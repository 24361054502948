import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  navigate(parts: string[]) {
    this.router.navigate(parts);
  }

  getForgotPasswordRoute(): string[] {
    return ['forgot-password'];
  }

  getDealDeskRoute(): string[] {
    return ['deal-desk'];
  }

  getDealDeskViewContractRoute(quoteRequestId: string): (string | number)[] {
    return [...this.getDealDeskRoute(), 'contract', quoteRequestId];
  }

  getDashboard(): string[] {
    return ['dashboard'];
  }

  getContracts(): string[] {
    return ['contracts'];
  }

  getBookedContracts(): string[] {
    return [...this.getContracts(), 'booked'];
  }

  getBiddingBase(): string[] {
    return ['bidding'];
  }

  getBidHistory(): string[] {
    return [...this.getBiddingBase(), 'history'];
  }

  getBidRequest(quoteRequestId: string): string[] {
    return [...this.getBiddingBase(), 'request', quoteRequestId];
  }

}
